import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const UPDATE_MINION = am(`UPDATE_MINION`);
const GET_MINIONS = am(`GET_MINIONS`);
const GET_MINION = am(`GET_MINION`);
const ADD_TO_MINIONS = am(`ADD_TO_MINIONS`);
const REMOVE_FROM_MINIONS = am(`REMOVE_FROM_MINIONS`);

export default {
  namespaced: true,
  ...factory({
    state: {
      collection: {},
      minion: {},
      isGetMinionsRequestPending: false,
      isGetMinionsRequestFailed: false,
      isGetMinionRequestPending: false,
      isGetMinionRequestFailed: false,
      isUpdateMinionRequestPending: false,
      isUpdateMinionRequestFailed: false,
      isAddMinionRequestPending: false,
      isAddMinionRequestFailed: false,
      isRemoveMinionRequestPending: false,
      isRemoveMinionRequestFailed: false
    },
    mutations: {
      [GET_MINIONS.STARTED](state) {
        state.isGetMinionsRequestFailed = false;
        state.isGetMinionsRequestPending = true;
        state.collection = [];
      },
      [GET_MINIONS.COMPLETED](state, collection) {
        state.collection = collection;

        state.isGetMinionsRequestPending = false;
      },
      [GET_MINIONS.FAILED](state) {
        state.isGetMinionsRequestFailed = true;
        state.isGetMinionsRequestPending = false;
      },
      [GET_MINION.STARTED](state) {
        state.isGetMinionRequestFailed = false;
        state.isGetMinionRequestPending = true;
        state.minion = {};
      },
      [GET_MINION.COMPLETED](state, minion) {
        state.isGetMinionRequestPending = false;
        state.minion = minion;
      },
      [GET_MINION.FAILED](state) {
        state.isGetMinionsRequestFailed = true;
        state.isGetMinionsRequestPending = false;
      },
      [ADD_TO_MINIONS.STARTED](state) {
        state.isAddMinionRequestPending = true;
        state.isAddMinionRequestFailed = false;
      },
      [ADD_TO_MINIONS.COMPLETED](state, item) {
        state.collection.data.push(item);

        state.isAddMinionRequestPending = false;
      },
      [ADD_TO_MINIONS.FAILED](state) {
        state.isAddMinionRequestPending = false;
        state.isAddMinionRequestFailed = true;
      },
      // [UPDATE_MINION.STARTED](state) {
      //   state.isUpdateMinionRequestPending = true;
      //   state.isUpdateMinionRequestFailed = false;
      // },
      // [UPDATE_MINION.COMPLETED](state, invention) {
      //   state.isUpdateMinionRequestPending = false;
      //   const existing = state.my.data.find(i => i.id === invention.id);

      //   if (!existing) {
      //     return;
      //   }

      //   existing.title = invention.title;
      //   existing.references = invention.references;

      //   if (state.item?.id === invention.id) {
      //     state.item.title = invention.title;
      //     state.item.references = invention.references;
      //   }
      // },
      // [UPDATE_MINION.FAILED](state) {
      //   state.isUpdateMinionRequestPending = false;
      //   state.isUpdateMinionRequestFailed = true;
      // },
      [REMOVE_FROM_MINIONS.STARTED](state) {
        state.isRemoveMinionRequestPending = true;
        state.isRemoveMinionRequestFailed = false;
      },
      [REMOVE_FROM_MINIONS.COMPLETED](state, { id }) {
        state.collection.data = state.collection.data.filter(item => item.documentId !== id);
        state.collection.meta.pagination.total = state.collection.data.length;
        state.isRemoveMinionRequestPending = false;
      },
      [REMOVE_FROM_MINIONS.FAILED](state) {
        state.isRemoveMinionRequestPending = false;
        state.isRemoveMinionRequestFailed = true;
      }
    },
    actions: {
      async getMinions({ commit }) {
        try {
          commit(GET_MINIONS.STARTED);

          const collection = await httpClient.get('/api/streams/minion-dojo-api/api/minions?populate=*&pagination[pageSize]=250');

          commit(GET_MINIONS.COMPLETED, collection);
        } catch (e) {
          commit(GET_MINIONS.FAILED);
          throw e;
        }
      },
      async getMinion({ commit }, id) {
        try {
          commit(GET_MINION.STARTED);
          const minion = await httpClient.get(`/api/streams/minion-dojo-api/api/minions/${id}?populate=*`);
          commit(GET_MINION.COMPLETED, minion.data);
        } catch (e) {
          commit(GET_MINION.FAILED);
          throw e;
        }
      },
      async add({ commit }, item) {
        try {
          commit(ADD_TO_MINIONS.STARTED);
          const collection = (await this.getMinions()) ?? [];
          if (!collection.find(({ id }) => item.documentId === id)) {
            const response = await httpClient.post(`/api/streams/minion-dojo-api/api/minions`, { data: { ...item } });

            if (!response.ok) {
              throw new Error(`Failed to add new item with name: ${item.name}`);
            }

            commit(ADD_TO_MINIONS.COMPLETED, item);
          }
        } catch (e) {
          commit(ADD_TO_MINIONS.FAILED);
          throw e;
        }
      },
      async remove({ commit }, { id }) {
        try {
          commit(REMOVE_FROM_MINIONS.STARTED);

          const response = await httpClient.delete(`/api/streams/minion-dojo-api/api/minions${id}`);

          if (response.status !== 204) {
            throw new Error(`Failed to delete item with id: ${id}`);
          }

          commit(REMOVE_FROM_MINIONS.COMPLETED, { id });
        } catch (e) {
          commit(REMOVE_FROM_MINIONS.FAILED);
          throw e;
        }
      }
      // async updateInvention({ commit }, { id, title, references }) {
      //   try {
      //     commit(UPDATE_MINION.STARTED);
      //     const updated = await httpClient.patch(`/api/inventions/${id}`, {
      //       title,
      //       references,
      //       referenceOverride: true
      //     });
      //     commit(UPDATE_MINION.COMPLETED, updated);
      //   } catch {
      //     commit(UPDATE_MINION.FAILED);
      //   }
      // }
    }
  })('minions')
};
