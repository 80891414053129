import { createStore } from 'vuex';

import identity from './identity';
import authentication from './authentication';
import inventions from './inventions';
import stream from './stream';
import ifw_stream from './ifw-stream';
import messages from './messages';
import tasks from './tasks';
import applications from './applications';
import boards from './boards';
import filters from './filters';
import reports from './reports/';
import steps from './steps';
import milestones from './milestones';
import workflows from './workflows';
import emails from './emails';
import docketing from './docketing';
import milestoneNotes from './milestoneNotes';
import inventionNotes from './inventionNotes';
import directoryIndex from './directoryIndex';
import milestonesOverview from './milestonesOverview';
import settings from './settings';
import inventionFiles from './inventionFiles';
import minions from './minions';
import comments from './comments';
import minionTasks from './minionTasks';

import httpClient from '@/utils/httpClient';

import { set, get } from '@/utils/rs';

const pinnedLinksKey = 'HUB_PINNED_LINKS';

export default async (initialState = {}) => {
  const pinnedLinks = await get(pinnedLinksKey);
  return createStore({
    modules: {
      identity,
      authentication,
      inventions,
      stream,
      ifw_stream,
      messages,
      tasks,
      applications,
      boards,
      filters,
      reports,
      steps,
      milestones,
      workflows,
      emails,
      docketing,
      milestoneNotes,
      directoryIndex,
      milestonesOverview,
      inventionNotes,
      settings,
      inventionFiles,
      minions,
      comments,
      minionTasks
    },
    state: {
      ...initialState,
      bots: [],
      pinnedLinks: pinnedLinks || [],
      inHouseAttorneys: []
    },
    mutations: {
      BOTS_LIST_FETCHED(state, list) {
        state.bots = list;
      },
      IN_HOUSE_ATTORNEYS_FETCHED(state, list) {
        state.inHouseAttorneys = list;
      },
      TOGGLE_PINNED_LINK(state, link) {
        const index = state.pinnedLinks.findIndex(l => l.to === link.to);
        if (index < 0) {
          state.pinnedLinks.push(link);
        } else {
          state.pinnedLinks.splice(index, 1);
        }
      }
    },
    actions: {
      async EXECUTE_BOTS_UPDATE({ commit, dispatch }) {
        try {
          const list = await httpClient.get(`/api/auth/bots`);

          const existArr = await httpClient.get(`/api/auth/bots/scripts/validate?${list.map(bot => `bots=${bot.email}`).join('&')}`);
          const bots = list.filter((b, index) => existArr[index].exists).map(b => b.email);

          commit('BOTS_LIST_FETCHED', bots);
        } catch (e) {
          // do nothing
        }

        setTimeout(async () => {
          await dispatch('EXECUTE_BOTS_UPDATE');
        }, 5 * 60 * 1000);
      },
      async TOGGLE_PINNED_LINK({ commit, state }, link) {
        commit('TOGGLE_PINNED_LINK', link);
        await set(pinnedLinksKey, state.pinnedLinks);
      },
      async GET_INHOUSE_ATTORNEYS({ commit }) {
        try {
          const list = await httpClient.get('/api/search/tasks/inhouseattorneys');
          commit('IN_HOUSE_ATTORNEYS_FETCHED', list);
        } catch (e) {
          // do nothing
        }
      }
    }
  });
};
