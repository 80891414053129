<template>
  <hub-header v-if="!previewMode"></hub-header>
  <div v-else></div>
  <main v-if="isAuthenticated">
    <hub-shell />
  </main>
  <main v-else-if="isReady">
    <hub-login />
  </main>
</template>

<script>
import { mapState } from 'vuex';

import Login from '@/components/Login';
import Header from '@/components/Header';
import Shell from '@/components/Shell';

export default {
  components: {
    'hub-login': Login,
    'hub-header': Header,
    'hub-shell': Shell
  },
  computed: {
    ...mapState({
      isReady: s => s.identity.isReady,
      isAuthenticated: s => s.identity.isAuthenticated,
      previewMode: s => s.previewMode || s.fipMode
    })
  },
  created() {
    this.$store.dispatch('identity/initialize').then(() => (this.isAuthenticated ? this.$store.dispatch('directoryIndex/initialize') : null));
    this.$store.dispatch('EXECUTE_BOTS_UPDATE');
    this.$store.dispatch('GET_INHOUSE_ATTORNEYS');
  }
};
</script>

<style lang="scss">
:root {
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
:root {
  // --theme-default: #607d8b;
  // --theme-default-light: rgba(0, 0, 0, 0.12);
  // --theme-default-dark: #455a64;

  --theme-primary: #90caf9;
  --theme-primary-accent: #648dae;

  --theme-secondary: #f48fb1;
  --theme-secondary-accent: #aa647b;

  // #e80a89
  --theme-highlight: rgba(255, 255, 255, 0.12);

  --theme-success: #4caf50;
  --theme-success-rgb: 76, 175, 80;

  --theme-error: #f44336;
  --theme-error-rgb: 244, 67, 54;

  --theme-warning: #ff9800;
  --theme-warning-rgb: 255, 152, 0;

  --theme-background: #303030;
  --theme-surface: #424242;

  --popper-theme-background-color: #303030;
  --popper-theme-background-color-hover: #303030;
  --popper-theme-text-color: #ffffff;

  --theme-on-primary: rgba(0, 0, 0, 0.87);
  --theme-on-secondary: rgba(0, 0, 0, 0.87);
  --theme-on-warning: #fff;
  --theme-on-error: #fff;
  --theme-on-success: #fff;

  --theme-on-background: #fff;
  --theme-on-background-accent: rgba(255, 255, 255, 0.7);
  --theme-on-surface: #fff;
  --theme-on-surface-accent: rgba(255, 255, 255, 0.7);

  --theme-scroll-width: 6px;
  --theme-scroll-height: 6px;

  --ms-bg: var(--theme-background);
  --ms-bg-disabled: var(--theme-background);
  --ms-tag-bg: var(--theme-primary);
  --ms-border-color: var(--theme-on-surface);
  --ms-spinner-color: var(--theme-primary);
  --ms-tag-color: var(--theme-background);
  --ms-dropdown-bg: var(--theme-background);
  --ms-option-bg-selected: var(--theme-primary);
  --ms-option-bg-selected-pointed: var(--theme-primary);
}

.theme-light {
  --theme-primary: #1976d2;
  --theme-primary-accent: #115293;

  --theme-secondary: #ffd600;

  // #e80a89
  --theme-highlight: rgba(0, 0, 0, 0.15);

  --theme-success: #4caf50;
  --theme-error: #f44336;
  --theme-warning: #ff9800;

  --theme-background: #fff;
  --theme-surface: #eeeeee;

  --popper-theme-background-color: #fff;
  --popper-theme-background-color-hover: #fff;
  --popper-theme-text-color: #000;

  --theme-on-primary: #fff;
  --theme-on-secondary: #2c3e50;

  --theme-on-warning: #fff;
  --theme-on-error: #fff;
  --theme-on-success: #fff;

  --theme-on-background: #000;
  --theme-on-background-accent: #455a64;
  --theme-on-surface: #000;
  --theme-on-surface-accent: #455a64;

  --theme-scroll-width: 6px;
  --theme-scroll-height: 6px;

  --ms-bg: var(--theme-background);
  --ms-bg-disabled: var(--theme-background);
  --ms-tag-bg: var(--theme-primary);
  --ms-border-color: var(--theme-on-surface);
  --ms-spinner-color: var(--theme-primary);
  --ms-tag-color: var(--theme-background);
  --ms-dropdown-bg: var(--theme-background);
  --ms-option-bg-selected: var(--theme-primary);
  --ms-option-bg-selected-pointed: var(--theme-primary);
}
</style>

<style lang="scss">
html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 100%;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-background);
  color: var(--theme-on-background);
  width: 100%;
  height: 100%;
}

body {
  // padding: 5px;
  min-width: 768px;
  font-size: 14px;
}

a {
  color: var(--theme-primary);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.5s;

  &.disabled,
  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    box-shadow: #eee;
    color: var(--theme-primary-accent);
  }
}

hr {
  border-color: var(--theme-highlight);
  margin: 1.5rem 0;
}

.application {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
}

main {
  overflow-y: scroll;
  overflow-x: auto;
}
aside {
  overflow-y: auto;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  background-color: var(--theme-surface);
  color: var(--theme-on-surface-accent);
  font-weight: 700;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h5 {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--theme-on-surface-accent);
  font-weight: 700;
}

form {
  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;

    &.center {
      text-align: center;
    }
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}

.disabled {
  opacity: 0.5;
}
</style>

<style>
::-webkit-scrollbar {
  width: var(--theme-scroll-width);
  height: var(--theme-scroll-height);
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: var(--theme-highlight);
  border-radius: 2px;
  box-shadow: 0px 0px 1px 1px var(--theme-highlight) inset;
}
::-webkit-scrollbar-corner {
  background-color: var(--theme-surface);
}
::-webkit-resizer {
  background-color: var(--theme-surface);
}

div[contenteditable] {
  outline: none;
}
</style>
